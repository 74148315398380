import axios from "axios";
export const AddToStake = (stake, setStake, chipSelection) => {
  let aux = [...stake];
  let chipId = chipSelection.indexOf(1);
  let amount;
  switch (chipId) {
    case 0:
      amount = 1;
      break;
    case 1:
      amount = 5;
      break;
    case 2:
      amount = 10;
      break;
    case 3:
      amount = 25;
      break;
    case 4:
      amount = 50;
      break;
    case 5:
      amount = 100;
      break;
    default:
      break;
  }
  if (getTotalBet(stake) + amount > 1000) {
    alert("Maximum bet amount is 1000 CDF");
  } else {
    let auxx = {
      amount: amount,
    };
    aux.push(auxx);
    setStake(aux);

    let xx = new Audio("./audio/bet-coin1.mp3");
    xx.play();
  }
};

export const getTotalBet = (stake) => {
  if (stake.length > 0) {
    let sum = 0;
    stake.forEach((elem) => {
      sum += elem.amount;
    });
    return sum;
  } else return 0;
};

export const getBalance = (gameInit, bookresponse) => {
  if (bookresponse != null) {
    return bookresponse.content.balance;
  } else return gameInit.totalBalance;
};

export const getWinAmount = (bookResponse) => {
  if (bookResponse != null) {
    return bookResponse.content.winAmount;
  } else return "0";
};
export const UndoSelection = (stake, setStake) => {
  let aux = [...stake];
  aux.pop();
  setStake(aux);
};

export const ClearSelection = (setStake) => {
  setStake([]);
};

export const DoubleSelection = (stake, setStake) => {
  let aux = [...stake];
  for (let i = 0; i < stake.length; i++) {
    if (getTotalBet(aux) < 1000) aux.push(aux[i]);
  }
  setStake(aux);
};
export const LoginToGame = async (setTkn, setGame, user, pass) => {
  const data = {
    userName: user,
    password: pass,
    clientId: "",
  };
  await axios
    .post(
      "https://zlab-authentication-service.syncrogames.com/api/UserAuthenticator/authenticate",
      data,
      null
    )
    .then((response) => {
      setTkn(response.data.keyValue);
      setGame(response.data);
    });
};

export const createJson = (stake, setJson, gameInit) => {
  let bets = 0;
  for (let i = 0; i < stake.length; i++) {
    bets += stake[i].amount;
  }
  let finalJson = {
    betAmount: bets,
    currency: gameInit.currency,
  };
  setJson(finalJson);
};

export const BookFirstPart = async (tkn, json, setter) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  await axios
    .post(
      "https://api-lto.zarlab.dev/games/biggerDice/playerThrow",
      json,
      config
    )
    .then((response) => {
      setter(response.data);
    });
};

export const BookSecondPart = async (tkn, roundNo, json, setter) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  await axios
    .post(
      "https://api-lto.zarlab.dev/games/biggerDice/oponentThrow?roundNumber=" +
        `${roundNo}`,
      json,
      config
    )
    .then((response) => {
      setter(response.data);
    });
};

export const GetHistory = async (size, tkn, setMessage) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  let url;
  if (size == null) {
    url = "https://api-lto.zarlab.dev/games/biggerDice/history";
  } else {
    url =
      "https://api-lto.zarlab.dev/games/biggerDice/history?sampleSize=" +
      `${size}`;
  }

  await axios.get(url, config).then((response) => {
    setMessage(response.data);
  });
};
export const GetSpecHistory = async (round, tkn, setter) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  await axios
    .get(
      "https://api-lto.zarlab.dev/games/biggerDice/roundInfo?roundId=" +
        `${round}`,
      config
    )
    .then((response) => setter(response.data));
};
export const renderGifSrc = (dice1, dice2) => {
  return "./gifs/" + dice1 + dice2 + ".gif";
};
