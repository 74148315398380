import { Modal } from "@material-ui/core";
import React from "react";

const ModalHistorySingle = (props) => {
  const fromUtcToDisplay = (utc) => {
    let ddd = new Date(utc);
    let day =
      ddd
        .toLocaleString("EN-en", { weekday: "short" })
        .charAt(0)
        .toUpperCase() +
      ddd.toLocaleString("EN-en", { weekday: "short" }).slice(1);
    let dayNum = ddd.getDate() > 9 ? ddd.getDate() : "0" + ddd.getDate();
    let month =
      ddd.getMonth() + 1 > 9 ? ddd.getMonth() + 1 : "0" + (ddd.getMonth() + 1);
    let hour = ddd.getHours() > 9 ? ddd.getHours() : "0" + ddd.getHours();
    let mins = ddd.getMinutes() > 9 ? ddd.getMinutes() : "0" + ddd.getMinutes();
    let sec = ddd.getSeconds() > 9 ? ddd.getSeconds() : "0" + ddd.getSeconds();

    return (
      dayNum +
      "." +
      month +
      "." +
      ddd.getFullYear() +
      " , " +
      hour +
      ":" +
      mins +
      ":" +
      sec
    );
  };

  const renderDice = (stringResult) => {
    let firstDice = stringResult;
    if (firstDice == 1) {
      return (
        <img
          src="./dices/dice1.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 2) {
      return (
        <img
          src="./dices/dice2.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 3) {
      return (
        <img
          src="./dices/dice3.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 4) {
      return (
        <img
          src="./dices/dice4.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 5) {
      return (
        <img
          src="./dices/dice5.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 6) {
      return (
        <img
          src="./dices/dice6.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    }
  };
  return (
    props.value && (
      <Modal
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            props.onClose();
          }
        }}
        className="modalhist_root"
      >
        <div className="modal_hist_wrap">
          <>
            <img src="./frameup.png" className="modal_frameup" />
            <img src="./framedown.png" className="modal_framedown" />
            <img src="./frameleft.png" className="modal_frameleft" />
            <img src="./frameright.png" className="modal_frameright" />
            <img src="./cornerleftdown.png" className="corder_left_down" />
            <img src="./cornerleftup.png" className="corder_left_up" />
            <img src="./cornerrightdown.png" className="corder_right_down" />
            <img src="./cornerrightup.png" className="corder_right_up" />
          </>
          <div className="history_title">
            <div className="hist_lbl">
              History for Round {props.id.roundNumber}
            </div>
            <div className="close_button" onClick={() => props.onClose()}>
              X
            </div>
          </div>
          <div className="history_list_titles">
            <div className="label_round">Round ID</div>
            <div className="label_date">Date</div>
            <div className="label_stake">Total Stake</div>
            <div className="label_win">Total Win</div>
          </div>
          <div
            className="history_single_root hist_act"
            style={{ marginTop: "30px" }}
          >
            <div className="label_round_s">{props.id.roundNumber}</div>
            <div className="label_date_s">
              {fromUtcToDisplay(props.id.throwTime)}
            </div>
            <div className="label_stake_s">
              {props.id.stake} {props.id.currency}
            </div>
            <div className="label_win_s">
              {props.id.winAmount} {props.id.currency}
            </div>
          </div>
          <div className="history_details_gen">
            <div className="details_old_bal">
              <span>Balance Before:</span> {props.value.content.oldBalance}
              {"  "}
              {props.value.content.currency}
            </div>
            <div className="details_round_details">
              <div className="round_det_dices">
                <span>Throw Result:</span>{" "}
                {renderDice(props.value.content.playerThrow.split(":")[0])}
                {renderDice(props.value.content.playerThrow.split(":")[1])}
              </div>

              <div className="round_det_stake">
                <span>Total stake:</span>
                {props.value.content.totalStake} {props.value.content.currency}
              </div>
              <div className="round_det_stake">
                <span>Total win:</span>
                {props.value.content.totalWin} {props.value.content.currency}
              </div>
            </div>
            <div className="details_new_bal">
              <span>Balance After:</span> {props.value.content.newBalance}
              {"  "}
              {props.value.content.currency}
            </div>
            <div className="opp_throw_single">
              <span>Opp. Result:</span>{" "}
              {renderDice(props.value.content.oponentThrow.split(":")[0])}
              {renderDice(props.value.content.oponentThrow.split(":")[1])}
            </div>
            {/* {props.value.content.betZones.map((elem, key) => {
              return (
                <div className="details_each_bet" key={key}>
                  <div className="each_zone">
                    <span>Played Zone:</span> {elem.zone}
                  </div>
                  <div className="stake_zone">
                    <span>Stake:</span> {elem.stake}
                    {props.value.content.currency}
                  </div>
                  <div className="win_zone">
                    <span>Win:</span> {elem.win} {props.value.content.currency}
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </Modal>
    )
  );
};

export default ModalHistorySingle;
