import React from "react";
import {
  ClearSelection,
  createJson,
  DoubleSelection,
  UndoSelection,
} from "../Utilities";

const PlayButtons_Mobile = (props) => {
  return (
    <div className="play_buttons_mobile">
      <div className="left_play_button">
        <div onClick={() => UndoSelection(props.stake, props.setStake)}>
          <div className="button_undo"></div>
        </div>
        <div onClick={() => ClearSelection(props.setStake)}>
          <div className="button_clear"></div>
        </div>
      </div>
      <div className="right_play_button">
        <div
          onClick={() => {
            if (props.tkn) {
              createJson(props.stake, props.setJson, props.gameInit);
              props.setPcStake(props.stake);
            } else {
              alert("Please Login to play the Game!");
            }
          }}
        >
          <div className="button_throw"></div>
        </div>
        <div
          onClick={() => {
            if (props.tkn) {
              DoubleSelection(props.stake, props.setStake);
            } else alert("Please Login to play the Game!");
          }}
        >
          <div className="button_double"></div>
        </div>
      </div>
    </div>
  );
};

export default PlayButtons_Mobile;
