import React from "react";
import { Modal } from "@material-ui/core";
import ModalHistory_SingleRow from "./ModalHistory_SingleRow";

const ModalHistory = (props) => {
  return (
    <Modal open={props.open} onClose={props.onClose} className="modalhist_root">
      <div className="modal_hist_wrap">
        <>
          <img src="./frameup.png" className="modal_frameup" />
          <img src="./framedown.png" className="modal_framedown" />
          <img src="./frameleft.png" className="modal_frameleft" />
          <img src="./frameright.png" className="modal_frameright" />
          <img src="./cornerleftdown.png" className="corder_left_down" />
          <img src="./cornerleftup.png" className="corder_left_up" />
          <img src="./cornerrightdown.png" className="corder_right_down" />
          <img src="./cornerrightup.png" className="corder_right_up" />
        </>
        <div className="history_title">
          <div className="hist_lbl">HISTORY</div>
          <div className="close_button" onClick={() => props.onClose()}>
            X
          </div>
        </div>
        <div className="history_list_titles">
          <div className="label_round">Round ID</div>
          <div className="label_date">Date</div>
          <div className="label_stake">Total Stake</div>
          <div className="label_win">Total Win</div>
        </div>
        <div className="history_list">
          {props.hist &&
            props.hist.content.map((elem, key) => {
              return (
                <ModalHistory_SingleRow row={elem} tkn={props.tkn} key={key} />
              );
            })}
        </div>
      </div>
    </Modal>
  );
};

export default ModalHistory;
