import React from "react";
import { ClearSelection, GetHistory } from "../Utilities";

const MobileDropDown = (props) => {
  return (
    <div className="mobile_drop_root">
      <ul className="mobile_drop_list">
        <li
          className="mobile_drop_item"
          onClick={(e) => {
            if (props.tkn) {
              e.preventDefault();
              GetHistory(null, props.tkn, props.setFullHistory);
              props.openModal();
            } else alert("Please Login to play the Game!");
          }}
        >
          HISTORY
        </li>
        <li
          className="mobile_drop_item"
          onClick={(e) => {
            if (props.tkn) {
              e.preventDefault();
              alert("menu");
            } else alert("Please Login to play the Game!");
          }}
        >
          MENU
        </li>
        <p style={{ color: "white", width: "100%", textAlign: "center" }}>_</p>
        {props.tkn === null ? (
          <li
            className="mobile_drop_item"
            onClick={(e) => {
              e.preventDefault();
              props.handleOpenUserModal();
            }}
          >
            LOGIN
          </li>
        ) : (
          <li
            className="mobile_drop_item"
            onClick={(e) => {
              e.preventDefault();
              props.setTkn(null);
              ClearSelection(props.setStake);
              props.setMobileMenuDropdown(false);
            }}
          >
            LOGOFF
          </li>
        )}
      </ul>
    </div>
  );
};

export default MobileDropDown;
