import React from "react";
import { Modal } from "@material-ui/core";
import "./ModalUser.css";
import { LoginToGame } from "../Utilities";
const ModalUser = (props) => {
  return (
    <Modal open={props.open} onClose={props.onClose} className="modaluser_root">
      <div className="modaluser_wrap">
        <>
          <img src="./frameup.png" className="modal_frameup_u" />
          <img src="./framedown.png" className="modal_framedown_u" />
          <img src="./frameleft.png" className="modal_frameleft_u" />
          <img src="./frameright.png" className="modal_frameright_u" />
          <img src="./cornerleftdown.png" className="corder_left_down_u" />
          <img src="./cornerleftup.png" className="corder_left_up_u" />
          <img src="./cornerrightdown.png" className="corder_right_down_u" />
          <img src="./cornerrightup.png" className="corder_right_up_u" />
        </>
        <div className="modaluser_head">
          <div className="modaluser_title">LOGIN</div>
          <div onClick={props.onClose} className="modaluser_icon">
            X
          </div>
        </div>
        <div className="modaluser_body">
          <div className="modaluser_forms">
            <form className="form_group">
              <div className="form_field_user">
                <label className="input_label">Username</label>
                <div className="input_field_box">
                  <input
                    className="input_field"
                    placeholder="Your username"
                    value={props.userName}
                    onChange={(e) => props.changeUser(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="form_field_user pass_input">
                <label className="input_label">
                  <div>Password</div>
                  {/* <div className="forgot_pass">Ai uitat parola?</div> */}
                </label>
                <input
                  className="input_field"
                  type="password"
                  autoComplete
                  placeholder="Type password"
                  value={props.pass}
                  onChange={(e) => props.changePass(e.target.value)}
                ></input>
              </div>
            </form>
            <button
              type="submit"
              className="modal_login_button"
              onClick={(e) => {
                LoginToGame(
                  props.setTkn,
                  props.setGame,
                  props.userName,
                  props.pass
                );
                e.preventDefault();
                props.onClose();
              }}
            >
              LOG INTO ACCOUNT
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalUser;
