import React, { useEffect, useState } from "react";
import { GetHistory, GetSpecHistory } from "../Utilities";
import ModalHistorySingle from "./ModalHistorySingle";
// import { GetHistory, GetSpecHistory } from "../Utilities";
// import ModalHistorySingle from "./ModalHistorySingle";

const HistoryDropDown = (props) => {
  const [openShortModal, setOpenShortModal] = useState(false);
  const handleOpenShortModal = () => setOpenShortModal(true);
  const handleCloseShortModal = () => setOpenShortModal(false);

  const renderDice = (stringResult) => {
    let firstDice = stringResult;
    if (firstDice == 1) {
      return (
        <img
          src="./dices/dice1.png"
          width="15px"
          height="15px"
          className="res_dice"
        />
      );
    } else if (firstDice == 2) {
      return (
        <img
          src="./dices/dice2.png"
          width="15px"
          height="15px"
          className="res_dice"
        />
      );
    } else if (firstDice == 3) {
      return (
        <img
          src="./dices/dice3.png"
          width="15px"
          height="15px"
          className="res_dice"
        />
      );
    } else if (firstDice == 4) {
      return (
        <img
          src="./dices/dice4.png"
          width="15px"
          height="15px"
          className="res_dice"
        />
      );
    } else if (firstDice == 5) {
      return (
        <img
          src="./dices/dice5.png"
          width="15px"
          height="15px"
          className="res_dice"
        />
      );
    } else if (firstDice == 6) {
      return (
        <img
          src="./dices/dice6.png"
          width="15px"
          height="15px"
          className="res_dice"
        />
      );
    }
  };

  const [shortHist, setShortHist] = useState(null);
  const [shortId, setShortId] = useState(null);
  const renderLast5 = () => {
    let x = [];
    for (let i = 0; i < props.hist.content.length; i++) {
      x.push(
        <li
          className="dropdown_item"
          onClick={() => {
            GetSpecHistory(
              props.hist.content[i].roundNumber,
              props.tkn,
              setShortHist
            );
            setShortId(props.hist.content[i]);
            handleOpenShortModal();
          }}
          key={i}
        >
          <div style={{ fontSize: ".8vw" }}>
            Round {props.hist.content[i].roundNumber}
          </div>
          <div className="dropdown_res">
            Win: {props.hist.content[i].winAmount}, Result:
            {renderDice(props.hist.content[i].playerThrow.split(":")[0])}
            {renderDice(props.hist.content[i].playerThrow.split(":")[1])}
          </div>
        </li>
      );
    }

    return x;
  };

  useEffect(() => {
    console.log(props.hist);
  }, []);

  return (
    <>
      <div className="dropdown_root">
        <ul>
          {renderLast5()}
          <li
            className="dropdown_item"
            onClick={() => {
              GetHistory(null, props.tkn, props.setFullHistory);
              props.openModal();
            }}
          >
            See All Results
          </li>
        </ul>
      </div>
      {openShortModal === true ? (
        <ModalHistorySingle
          open={openShortModal}
          onClose={handleCloseShortModal}
          value={shortHist}
          id={shortId}
        />
      ) : null}
    </>
  );
};

export default HistoryDropDown;
