import React, { useState } from "react";
import { GetSpecHistory } from "../Utilities";

const ModalHistory_SingleRow = (props) => {
  const [spec, setSpec] = useState(null);
  const [open, setOpen] = useState(false);

  const fromUtcToDisplay = (utc) => {
    let ddd = new Date(utc);
    let day =
      ddd
        .toLocaleString("EN-en", { weekday: "short" })
        .charAt(0)
        .toUpperCase() +
      ddd.toLocaleString("EN-en", { weekday: "short" }).slice(1);
    let dayNum = ddd.getDate() > 9 ? ddd.getDate() : "0" + ddd.getDate();
    let month =
      ddd.getMonth() + 1 > 9 ? ddd.getMonth() + 1 : "0" + (ddd.getMonth() + 1);
    let hour = ddd.getHours() > 9 ? ddd.getHours() : "0" + ddd.getHours();
    let mins = ddd.getMinutes() > 9 ? ddd.getMinutes() : "0" + ddd.getMinutes();
    let sec = ddd.getSeconds() > 9 ? ddd.getSeconds() : "0" + ddd.getSeconds();

    return (
      dayNum +
      "." +
      month +
      "." +
      ddd.getFullYear() +
      " , " +
      hour +
      ":" +
      mins +
      ":" +
      sec
    );
  };

  const renderDice = (stringResult) => {
    let firstDice = stringResult;
    if (firstDice == 1) {
      return (
        <img
          src="./dices/dice1.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 2) {
      return (
        <img
          src="./dices/dice2.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 3) {
      return (
        <img
          src="./dices/dice3.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 4) {
      return (
        <img
          src="./dices/dice4.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 5) {
      return (
        <img
          src="./dices/dice5.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    } else if (firstDice == 6) {
      return (
        <img
          src="./dices/dice6.png"
          width="15px"
          height="15px"
          className="res_dice_sg"
        />
      );
    }
  };

  return (
    <>
      <div
        className={
          open ? "history_single_root hist_act" : "history_single_root"
        }
        onClick={() => {
          GetSpecHistory(props.row.roundNumber, props.tkn, setSpec);
          setOpen(!open);
        }}
      >
        <div className="label_round_s">
          {open ? "v" : ">"} {props.row.roundNumber}
        </div>
        <div className="label_date_s">
          {fromUtcToDisplay(props.row.throwTime)}
        </div>
        <div className="label_stake_s">
          {props.row.stake} {props.row.currency}
        </div>
        <div className="label_win_s">
          {props.row.winAmount} {props.row.currency}
        </div>
      </div>
      {spec && open && (
        <div className="history_details_gen">
          <div className="details_old_bal">
            <span>Balance Before:</span> {spec.content.oldBalance}
            {"  "}
            {spec.content.currency}
          </div>
          <div className="details_round_details">
            <div className="round_det_dices">
              <span>Your Throw:</span>{" "}
              {renderDice(spec.content.playerThrow.split(":")[0])}
              {renderDice(spec.content.playerThrow.split(":")[1])}
            </div>
            <div className="round_det_stake">
              <span>Total stake:</span>
              {spec.content.totalStake} {spec.content.currency}
            </div>
            <div className="round_det_stake">
              <span>Total win:</span>
              {spec.content.totalWin} {spec.content.currency}
            </div>
          </div>
          <div className="details_new_bal">
            <span>Balance After:</span> {spec.content.newBalance}
            {spec.content.currency}
          </div>
          <div className="opp_throw">
            <span>Opp. Throw:</span>{" "}
            {renderDice(spec.content.oponentThrow.split(":")[0])}
            {renderDice(spec.content.oponentThrow.split(":")[1])}
          </div>
        </div>
      )}
    </>
  );
};

export default ModalHistory_SingleRow;
