import React from "react";
import {
  ClearSelection,
  createJson,
  DoubleSelection,
  getTotalBet,
  UndoSelection,
} from "../Utilities";

const PlayButtons = (props) => {
  return (
    <div className="bottom_buttons">
      <div onClick={() => UndoSelection(props.stake, props.setStake)}>
        <div className="button_undo"></div>
        {/* <span>UNDO</span> */}
      </div>
      <div
        onClick={() => {
          if (props.tkn) {
            if (getTotalBet(props.stake) < 1000) {
              createJson(props.stake, props.setJson, props.gameInit);
              props.setPcStake(props.stake);
            } else alert("Max bet allowed is: 1000 CDF");
          } else {
            alert("Please Login to play the Game!");
          }
        }}
      >
        <div className="button_throw"></div>
        {/* <span>THROW</span> */}
      </div>
      <div
        onClick={() => {
          if (props.tkn) {
            DoubleSelection(props.stake, props.setStake);
          } else alert("Please Login to play the Game!");
        }}
      >
        <div className="button_double"></div>
        {/* <span>DOUBLE</span> */}
      </div>
      <div onClick={() => ClearSelection(props.setStake)}>
        <div className="button_clear"></div>
        {/* <span>CLEAR</span> */}
      </div>
    </div>
  );
};

export default PlayButtons;
