import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BookSecondPart, renderGifSrc } from "../Utilities";
import "./SplashScreenV2.css";
import SpalshScreen_After from "./SplashScreen_After";

const SplashScreenV2 = (props) => {
  const [start, setStart] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStart(true);
    }, 1500);
  }, []);

  const [showFirstGif, setShowFirstGif] = useState(false);
  const [showSecondGif, setShowSecondGif] = useState(false);
  const [showSplash, setShowSplash] = useState(false);
  const [closeSplash, setCloseSplash] = useState(false);

  useEffect(() => {
    setShowSecondGif(true);
    setTimeout(() => {
      let xx = document.getElementsByClassName("gif_gif1")[0];
      xx.src = xx.src + "?a=" + Math.random();
      setShowFirstGif(true);
    }, 6851);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowSecondGif(false);
    }, 7500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCloseSplash(true);
    }, 20000);
  }, []);

  useEffect(() => {
    if (closeSplash) props.onClose();
  }, [closeSplash]);

  useEffect(() => {
    if (showFirstGif) {
      BookSecondPart(props.tkn, props.roundNo, props.json, props.setter);
      setTimeout(() => {
        let xx = document.getElementsByClassName("gif_gif2")[0];
        xx.src = xx.src + "?a=" + Math.random();
        setShowSecondGif(true);
        setShowSplash(true);
      }, 6851);
    }
  }, [showFirstGif]);

  const resolveImgSrc = (n) => {
    if (n == 1) return "./dices/dice1.png";
    if (n == 2) return "./dices/dice2.png";
    if (n == 3) return "./dices/dice3.png";
    if (n == 4) return "./dices/dice4.png";
    if (n == 5) return "./dices/dice5.png";
    if (n == 6) return "./dices/dice6.png";
  };

  return (
    <Modal
      open={props.open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          props.onClose();
        }
      }}
      className="splash_modal"
    >
      <div className="splash_container">
        <div className="splash_left_opp">
          {!showSecondGif && props.bookResponse2 && (
            <img
              src={renderGifSrc(
                props.bookResponse2.content.diceRoll.split(":")[0],
                props.bookResponse2.content.diceRoll.split(":")[1]
              )}
              className="gif_gif2"
            />
          )}
          {showSplash && (
            <>
              <div className="opp_throw_details">OPP. THROW</div>
              <img
                src={resolveImgSrc(
                  props.bookResponse2.content.diceRoll.split(":")[0]
                )}
                className="opp_throw_abs_dice"
              />
              <img
                src={resolveImgSrc(
                  props.bookResponse2.content.diceRoll.split(":")[1]
                )}
                className="opp_throw_abs_dice2"
              />
            </>
          )}
        </div>
        {start && (
          <div className="splash_right_opp">
            {!showFirstGif && (
              <img
                src={renderGifSrc(
                  props.bookResponse.content.diceResult.split(":")[0],
                  props.bookResponse.content.diceResult.split(":")[1]
                )}
                className="gif_gif1"
              />
            )}
            {showFirstGif && (
              <>
                <div className="your_throw_details">YOUR THROW</div>
                <img
                  src={resolveImgSrc(
                    props.bookResponse.content.diceResult.split(":")[0]
                  )}
                  className="your_throw_abs_dice"
                />
                <img
                  src={resolveImgSrc(
                    props.bookResponse.content.diceResult.split(":")[1]
                  )}
                  className="your_throw_abs_dice2"
                />
              </>
            )}
          </div>
        )}
        {showSplash && (
          <SpalshScreen_After
            bookResponse={props.bookResponse}
            bookResponse2={props.bookResponse2}
            resolveImgSrc={resolveImgSrc}
          />
        )}
        {!showSplash && <img src="./versus.png" className="versus_splash" />}
      </div>
    </Modal>
  );
};

export default SplashScreenV2;
